// Get a RTDB instance
import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyB4jN5sUsuxgLmkY-E9vcXjq4kxub0vLaQ",
    authDomain: "calcium-ember-331616.firebaseapp.com",
    projectId: "calcium-ember-331616",
    storageBucket: "calcium-ember-331616.appspot.com",
    messagingSenderId: "671347758338",
    appId: "1:671347758338:web:9a0e01bc75e4bd8f511e1b",
    databaseURL: "https://calcium-ember-331616-default-rtdb.europe-west1.firebasedatabase.app"
};

const app = initializeApp(firebaseConfig);

export const db = getDatabase(app);