<template>
  <div style="width:100%;margin:48px auto;">
    <slider ref="slider" :options="options">
      <slideritem v-for="(img,index) in imgs" :key="index" :style="{width: elementWidth}">
        <img :src="'images/brands/'+img" :alt="img" class="w-3/4 mx-auto py-8">
      </slideritem>
      <div slot="loading">Caricamento...</div>
    </slider>
  </div>
</template>
<script>
import {slider, slideritem} from 'vue-concise-slider'

export default {
  data() {
    return {
      imgs: [
        '1200px-Ray-Ban_logo.svg.png',
        'Bulgari_logo.svg.png',
        '1280px-Diesel_logo.svg.png',
        'police.png',
        'damiani.svg',
        'Logo_Liu_Jo.png',
        '1280px-Logo_carrera.svg.png',
        'logo-CapriVice.png',
        'blumarine-1.png',
        '1280px-Oakley_logo.svg.png'
      ],
      options: {
        currentPage: 0,
        autoplay: '4000',
        loop: true,
        pagination: false,
        slidesToScroll: 5
      },
      elementWidth: '20%'
    }
  },
  components: {
    slider,
    slideritem
  },
  beforeMount() {
    if (window.innerWidth < 1000) {
      this.options.slidesToScroll = 2
      this.elementWidth = '50%'
    }
  }
}
</script>
