import Vue from 'vue'
import App from './App.vue'
import * as GmapVue from 'gmap-vue'


/*
import Hotjar from 'vue-hotjar';
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "G-LKKFBQ5SP2" }
});

Vue.use (Hotjar, {
  id: '2659137'
})
*/


Vue.use(GmapVue, {
  load: {
    key: 'AIzaSyB4jN5sUsuxgLmkY-E9vcXjq4kxub0vLaQ',
    libraries: 'places',
  },
  installComponents: true
})

let VueCookie = require('vue-cookie');
Vue.use(VueCookie);

import VueRellax from 'vue-rellax'
Vue.use(VueRellax)

new Vue({
  render: h => h(App),
}).$mount('#app')

/*
const callAddFont = function () {
  this.addFileToVFS('Jost-Regular-normal.ttf', jost);
  this.addFont('Jost-Regular-normal.ttf', 'Jost-Regular', 'normal');
};
jsPDF.API.events.push(['addFonts', callAddFont])
*/
