<template>
  <div id="app" class="overflow-hidden bg-[#F3F9FC]">
    <!--  Header  -->
    <div class="flex justify-center bg-white">
      <img :src="activeShop.logo" alt="logo" class="p-5" style="width: 250px">
    </div>

    <!--  Hero  -->
    <div style="clip-path: url(#svgPath);"
         class="max-w-4xl mx-auto bg-[url('/images/specific/hero.jpg')] md:bg-[url('/images/specific/hero-desktop.jpg')] bg-cover bg-center bg-no-repeat flex flex-col gap-4 text-center font-light pt-8 pb-20 px-5 md:bg-center md:justify-center">
      <svg height="0" width="0">
        <defs>
          <clipPath id="svgPath" clipPathUnits="objectBoundingBox">
            <path d="M0,0.064 C0,0.029,0.036,0,0.08,0 H0.92 C0.964,0,1,0.029,1,0.064 V0.785 C1,0.81,0.981,0.833,0.953,0.844 L0.533,0.994 C0.512,1,0.488,1,0.467,0.994 L0.047,0.844 C0.019,0.833,0,0.81,0,0.785 V0.064" />
          </clipPath>
        </defs>
      </svg>

      <h1 class="text-white text-4xl">2 paia di occhiali progressivi <span class="font-extrabold text-[#FFEF00] drop-shadow-lg">firmati</span>
      </h1>
      <h3 class="font-extrabold text-xl uppercase text-orange-400 tracking-[.5em]">LENTI INCLUSE!</h3>
      <p class="text-white text-3xl">A SOLI</p>
      <div class="text-7xl font-extrabold text-[#FFEF00] drop-shadow leading-none">130€</div>
      <div>
        <button @click="downloadCoupon('mega130')"
                class="text-xl bg-orange-500 font-semibold text-white rounded-full tracking-widest py-3 px-5 uppercase drop-shadow">
          SCARICA COUPON
        </button>
      </div>
      <small class="text-white text-lg w-4/5 mx-auto">3000+ utenti hanno approfittato di questo offerta!</small>
    </div>
    <div class="container mx-auto px-5 -mt-16 z-10 relative">
      <img src="/images/specific/2-progressivi.png" alt="progressivi" class="mx-auto w-full max-w-xl">
    </div>

    <!--  Coupon 2  -->
    <div class="py-8 container mx-auto px-5 text-center">
      <h2 class="font-light text-5xl">Le offerte <span class="font-extrabold text-pink-700">continuano</span>!</h2>
    </div>

    <div style="clip-path: url(#svgPath2);" class="max-w-4xl mx-auto pt-24 pb-20 bg-gradient-to-br from-pink-900 to-violet-500">
      <svg height="0" width="0">
        <defs>
          <clipPath id="svgPath2" clipPathUnits="objectBoundingBox">
            <path d="M0,0.052 C0,0.021,0.06,0,0.113,0.013 L0.467,0.098 C0.488,0.103,0.512,0.103,0.533,0.098 L0.887,0.013 C0.94,0,1,0.021,1,0.052 V0.864 C1,0.881,0.981,0.896,0.953,0.903 L0.533,1 C0.512,1,0.488,1,0.467,1 L0.047,0.903 C0.019,0.896,0,0.881,0,0.864 L0,0.052" />
          </clipPath>
        </defs>
      </svg>

      <div class="container mx-auto px-6 text-center font-light flex flex-col gap-3">
        <h3 class="text-white text-3xl">2 paia di occhiali monofocali <span
            class="font-semibold text-[#FFEF00] drop-shadow">firmati</span></h3>
        <h3 class="font-extrabold text-xl uppercase text-orange-500 tracking-[.5em]">LENTI INCLUSE!</h3>
        <div>
          <img src="/images/specific/2-monofocali.png" alt="monofocali" class="mx-auto w-full max-w-xl">
        </div>
        <p class="text-white text-3xl">A SOLI</p>
        <div class="text-6xl font-extrabold text-[#FFEF00] drop-shadow leading-none">60€</div>
        <div>
          <button @click="downloadCoupon('mega60')"
                  class="bg-orange-500 font-semibold text-white rounded-full tracking-widest py-3 px-5 uppercase shadow ">
            SCARICA COUPON
          </button>
        </div>
        <small class="text-white text w-3/4 mx-auto">3000+ utenti hanno approfittato di questo offerta!</small>
      </div>
    </div>

    <!--  LAC  -->
    <div class="py-8 container mx-auto px-5 text-center">
      <h2 class="font-light text-5xl">É ora di fare <span class="font-extrabold text-[#4B51A1]">scorta</span>!</h2>
    </div>
    <div style="clip-path: url(#svgPath3);" class="max-w-4xl mx-auto py-24 container mx-auto px-6 text-center bg-gradient-to-br from-indigo-900 to-indigo-400">
      <svg height="0" width="0">
        <defs>
          <clipPath id="svgPath3" clipPathUnits="objectBoundingBox">
            <path d="M0,0.12 C0,0.048,0.06,0,0.113,0.029 L0.467,0.225 C0.488,0.237,0.512,0.237,0.533,0.225 L0.887,0.029 C0.94,0,1,0.048,1,0.12 V0.687 C1,0.726,0.981,0.762,0.953,0.778 L0.533,1 C0.512,1,0.488,1,0.467,1 L0.047,0.778 C0.019,0.762,0,0.726,0,0.687 L0,0.12" fill="#D9D9D9"/>
          </clipPath>
        </defs>
      </svg>

      <h3 class="text-white text-3xl">Lenti a contatto <span
          class="font-semibold text-[#FFEF00] drop-shadow">mensili</span> e <span
          class="font-semibold text-[#FFEF00] drop-shadow">giornaliere</span></h3>
    </div>

    <!--  Cards  -->
    <div class="-mt-16 mb-16 relative container mx-auto px-5 text-center grid md:grid-cols-2 gap-4 justify-items-center">
      <Card btn-text="SCARICA IL COUPON" @cta="downloadCoupon('lac')" class="mt-24">
        <div class="-mt-24">
          <img src="/images/specific/mega-clear-aqua.png" alt="mega clear aqua" class="mx-auto w-full max-w-xl">
        </div>
        <h3 class="pt-5 text-3xl font-thin">6 lenti <br/> <span class="font-bold">mensili</span></h3>
        <p class="py-3 font-thin text-xl">A SOLI</p>
        <div
            class="pb-3 text-6xl font-extrabold drop-shadow leading-none text-transparent bg-clip-text bg-gradient-to-r
          from-[#080A70] to-[#6392F8]">
          15€
        </div>
      </Card>

      <Card btn-text="SCARICA IL COUPON" @cta="downloadCoupon('lac')" class="mt-24">
        <div class="-mt-24">
          <img src="/images/specific/mega-clear-one-day-aqua.png" alt="mega clear aqua one day" class="mx-auto w-full max-w-xl">
        </div>
        <h3 class="pt-5 text-3xl font-thin">30 lenti <br/> <span class="font-bold">giornaliere</span></h3>
        <p class="py-3 font-thin text-xl">A SOLI</p>
        <div
            class="pb-3 text-6xl font-extrabold drop-shadow leading-none text-transparent bg-clip-text bg-gradient-to-r
          from-[#080A70] to-[#6392F8]">
          10€
        </div>
      </Card>
    </div>

    <div style="clip-path: url(#svgPath3);" class="max-w-4xl mx-auto py-24 container mx-auto px-6 text-center bg-gradient-to-br from-slate-700 to-teal-500">
      <h3 class="text-white text-3xl">Lenti a contatto con tecnologia <span
          class="font-semibold text-[#FFEF00] drop-shadow">HD-Hypergel</span></h3>
    </div>

    <!--  Cards  -->
    <div class="-mt-16 mb-28 relative container mx-auto px-5 text-center flex justify-center">
      <Card btn-text="SCARICA IL COUPON" @cta="downloadCoupon('lac')" class="mt-24">
        <div class="-mt-24">
          <img src="/images/specific/mega-clear-one-day-hd.png" alt="mega clear aqua one day hd" class="mx-auto w-full max-w-xl">
        </div>
        <h3 class="pt-5 text-3xl font-thin">30 lenti <br/> <span class="font-bold">giornaliere</span></h3>
        <p class="py-3 font-thin text-xl">A SOLI</p>
        <div
            class="pb-3 text-6xl font-extrabold drop-shadow leading-none text-transparent bg-clip-text bg-gradient-to-r
          from-[#515773] to-[#5CBBA4]">
          15€
        </div>
      </Card>
    </div>


    <div class="relative bg-[url('/images/specific/hero.jpg')] md:bg-[url('/images/specific/hero-desktop.jpg')] bg-cover bg-center bg-no-repeat">
      <div class="-top-16 absolute transform translate-x-1/2 right-1/2">
        <img src="images/specific/mappa.svg" class="pb-3" style="width: 150px;">
      </div>
      <div class="container mx-auto px-5 pb-8 pt-20 flex flex-col gap-3 text-white text-center">
        <h2 class="title-font font-bold text-3xl uppercase">VIENI A TROVARCI</h2>
        <p> {{ city }} per riscattare il tuo coupon!</p>

        <div class="py-3">
          <a v-if="validShop"
             :href="currentUrlForLatLng"
             @click="mapAction"
             target="_blank"
             class="bg-orange-500 font-semibold text-white rounded-full tracking-widest py-3 px-5 uppercase shadow">
            Apri la mappa
          </a>
          <button v-else
                  @click="mapAction(true)"
                  class="bg-orange-500 font-semibold text-white rounded-full tracking-widest py-3 px-5 uppercase shadow">
            Apri la mappa
          </button>
        </div>
      </div>

      <gmap-map
          v-if="!validShop && showMap"
          :center="center"
          :zoom="zoom"
          style="width:100%;  height: 555px; color: black">
        <gmap-marker
            :key="index"
            v-for="(gmp, index) in currentLocations"
            :position="gmp"
            :clickable="true"
            :draggable="false"
            @click="openInfoWindowTemplate(index)"
        ></gmap-marker>
        <gmap-info-window
            :options="{
                maxWidth: 300,
                pixelOffset: { width: 0, height: -35 }
              }"
            :position="infoWindow.position"
            :opened="infoWindow.open"
            @closeclick="infoWindow.open=false">
          <div v-html="infoWindow.template"></div>
        </gmap-info-window>
      </gmap-map>
    </div>

    <div class="container mx-auto px-5 py-12 text-center flex flex-col">
      <h2 class="font-thin uppercase tracking-widest">ISCRIVITI ALLA</h2>
      <h2 class="font-bold text-[#5D5BDE] text-3xl mt-2 mb-4">NEWSLETTER</h2>
      <div class="py-3">
        <a href="https://vqui.it/w/dArVc2w4"
           target="_blank"
           class="bg-yellow-300 font-semibold text-black rounded-full tracking-widest py-3 px-5 uppercase shadow">
          Iscriviti
        </a>
      </div>
    </div>

    <div class="container mx-auto px-5 text-center">
      <h2 class="text-[#5D5BDE] text-3xl font-extrabold">Ecco perchè siamo i più amati</h2>
      <p class="py-3">
        in ogni punto vendita troverai:
      </p>

      <div class="grid md:grid-cols-3">
        <div class="flex items-stretch">
          <div class="bg-white w-full text-black m-5 flex flex-col items-center shadow-lg rounded-lg p-5">
            <img src="/images/specific/assortimento.svg" style="width: 100px; padding-top: 10px; padding-bottom: 10px">
            <h3 class="text-[#5D5BDE] font-semibold text-lg">Assortimento</h3>
            <p>il più vasto possibile di occhiali e lenti da vista</p>
          </div>
        </div>
        <div class="flex items-stretch">
          <div class="bg-white w-full text-black m-5 flex flex-col items-center shadow-lg rounded-lg p-5">
            <img src="/images/specific/servizio.svg" style="width: 80px">
            <h3 class="text-[#5D5BDE] font-semibold text-lg">Servizio</h3>
            <p>il laboratorio garantisce montaggi perfetti</p>
          </div>
        </div>
        <div class="flex items-stretch">
          <div class="bg-white w-full text-black m-5 flex flex-col items-center shadow-lg rounded-lg p-5">
            <img src="/images/specific/convenienza.svg" style="width: 70px">
            <h3 class="text-[#5D5BDE] font-semibold text-lg">Convenienza</h3>
            <p>puoi toccarla con mano, il risparmio è sbalorditivo</p>
          </div>
        </div>
      </div>

    </div>

    <div class="bg-white">
      <div class="container mx-auto px-5">
        <logo-slider></logo-slider>

        <div class="pb-12 flex flex-col gap-3">
          <p>Promo attive dal 5 aprile fino al 5 maggio 2023 in tutti i negozi</p>
          <p>* Termini e condizioni in negozio, i coupon non sono cumulabili con altri buoni o
            promozioni
            in corso.
          </p>
          <p>
            Per la promo STILE: le lenti in omaggio devono essere di pari valore o inferiore al costo delle
            lenti
            dell'occhiale completo</p>
        </div>

        <div class="grid md:grid-cols-3">
          <div class="mx-auto">
            <img :src="activeShop.logo" alt="logo" style="width: 250px">
          </div>
          <div class="py-4 text-center">
            <p>Tel. <a :href="'tel:' + phone">{{ phone }}</a></p>
            <p><a :href="'mailto:' + email">{{ email }}</a></p>
          </div>
          <div class="flex justify-center">
            <a :href="activeShop.facebook"
               target="_blank">
              <img src="images/facebook.jpg" alt="facebook" style="width: 75px; margin-right: 10px; cursor: pointer">
            </a>
            <a :href="activeShop.instagram"
               target="_blank">
              <img src="images/instagram.jpg" alt="instagram" style="width: 75px; cursor: pointer">
            </a>
          </div>
        </div>

        <div class="text-center pt-4 pb-20 mb-5">
          <p>Per non ricevere più questo SMS
            <a class="text-blue-500" href="https://vqui.it/w/cV85YDUI" target="_blank">CLICCA QUI</a>
          </p>
        </div>
      </div>
    </div>

    <div v-if="showShareButton"
         class="rounded-full bg-orange-500 share-button"
         @click="shareButton"
         style="cursor: pointer">
      <share-icon :size="40" style="cursor:pointer;"/>
    </div>

    <cookie-law theme="dark-lime"
                message="Questo sito web utilizza i cookie per assicurarti la migliore esperienza sul nostro sito web."
                buttonText="Accetto"></cookie-law>

  </div>
</template>

<script>
import LogoSlider from "./components/LogoSlider";
import Card from "./components/Card";
import shopsData from "/src/assets/negoziCom.json"
import ShareIcon from 'vue-material-design-icons/ShareVariant.vue';
import CookieLaw from 'vue-cookie-law';
import {db} from './js/db.js';
import {ref, set} from "firebase/database";

export default {
  name: 'App',
  data() {
    return {
      shopId: '',
      shop: 'Centro Ottico Megavision',
      phone: '0533728544',
      email: 'online@centrootticomegavision.com',
      city: 'nel negozio più vicino',
      lat: null,
      lng: null,

      // Google Map
      showMap: false,
      center: {
        lat: 44.498955,
        lng: 11.327591
      },
      zoom: 7,
      locations: shopsData,
      infoWindow: {
        position: {lat: 0, lng: 0},
        open: false,
        template: ''
      },
      coupons: [
        'buono50',
        'promo30',
        'rayban30',
      ],

      showShareButton: false,
    }
  },
  beforeMount() {
    // Get id from query params
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    this.shopId = urlParams.get('n')
    if (this.shopId) {
      let shop = shopsData.find(shop => shop.id == this.shopId)
      if (shop) {
        this.shop = shop["Pos"];
        this.city = 'a ' + shop["Localita"];
        this.lat = shop["lat"];
        this.lng = shop["lng"];
        this.email = shop["E-mail"];
        this.phone = shop["Telefono"];
      } else {
        this.setLocationLatLng();
      }
    } else {
      this.setLocationLatLng();
    }

    const userId = urlParams.get('u')
    if (userId) {
      console.log(db)
      set(ref(db, 'users/' + userId), {
        id: userId,
        shop: this.shopId ?? 0
      });
    }
  },
  mounted() {
    if (!this.shopId) {
      if (window.location.hash === '#map') {
        this.mapAction(true)
      }
    }

    if (navigator.share) {
      this.showShareButton = true
    }

    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    if (urlParams.get('shop') === '') {
      if (this.$gtag) {
        this.$gtag.event('qr-mega')
      }
    } else if (urlParams.get('shop') === 'perris') {
      this.shop = 'Perris'
      if (this.$gtag) {
        this.$gtag.event('qr-perris')
      }
    }
  },
  computed: {
    activeShop() {
      if (this.shop === 'Perris') {
        return {
          cover: 'images/covers/cover-perris.jpg',
          coverMobile: 'images/covers/cover-perris-mobile.jpg',
          colour: 'red',
          logo: 'images/logo-perris.png',
          logoWhite: 'images/logo-perris-white.png',
          facebook: 'https://m.facebook.com/OtticaPerris/',
          instagram: 'https://www.instagram.com/centrootticoperris/',
          pdf: 'pdf/Coupon_Perris_',
        }
      } else if (this.shop === 'Brunozzi') {
        return {
          cover: 'images/covers/cover-brunozzi.jpg',
          coverMobile: 'images/covers/cover-brunozzi-mobile.jpg',
          colour: 'red',
          logo: 'images/logo-brunozzi.png',
          logoWhite: 'images/logo-brunozzi-white.png',
          facebook: 'https://m.facebook.com/otticabrunozziassisi/',
          instagram: 'https://www.instagram.com/otticabrunozzi/',
          pdf: 'pdf/Coupon_Brunozzi_',
        }
      }
      return {
        cover: 'images/covers/cover-com.jpg',
        coverMobile: 'images/covers/cover-com-mobile.jpg',
        colour: 'orange',
        logo: 'images/logo-com.png',
        logoWhite: 'images/logo-com-white.png',
        facebook: 'https://m.facebook.com/centrootticomegavision/',
        instagram: 'https://www.instagram.com/centrootticomegavision/',
        pdf: 'pdf/Coupon_COMegavision_',
      }
    },
    validShop() {
      return this.shopId && this.lat && this.lng
    },
    currentUrlForLatLng() {
      if (this.validShop) {
        return this.getUrlForLatLng(this.lat, this.lng)
      }
      return '#'
    },
    currentLocations() {
      if (this.shop === 'Perris') {
        return this.locations.filter(loc => loc['Pos'] === 'Perris')
      }
      return this.locations
    }
  },
  methods: {
    mapAction(showMap = false) {
      if (showMap) {
        this.showMap = true;
      }
      console.log('Map')
      if (this.$gtag) {
        this.$gtag.event('maps')
      }
    },
    downloadCoupon(promo) {
      const customEvent = 'download-coupon-' + promo
      const specificPdf = promo + '.pdf'
      if (this.$gtag) {
        this.$gtag.event('download-coupon', {value: this.shopId, 'shop-id': this.shopId})
        this.$gtag.event(customEvent)
      }

      window.open(this.activeShop.pdf + specificPdf, '_blank');
    },
    getUrlForLatLng(lat, lng) {
      if ((navigator.platform.indexOf("iPhone") != -1)
          || (navigator.platform.indexOf("iPod") != -1)
          || (navigator.platform.indexOf("iPad") != -1)) {
        return `https://maps.apple.com/?daddr=${lat},${lng}`
      } else {
        return `https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${lat},${lng}`
      }
    },
    setLocationLatLng: function () {
      navigator.geolocation.getCurrentPosition(geolocation => {
        this.center = {
          lat: geolocation.coords.latitude,
          lng: geolocation.coords.longitude
        };
        this.zoom = 10
      });
    },
    openInfoWindowTemplate(index) {
      const loc = this.currentLocations[index]
      this.infoWindow.position = {lat: loc.lat, lng: loc.lng}
      this.infoWindow.template = `<b>${loc['Nome']}</b><br>${loc['Indirizzo']}, ${loc['Cap']} ${loc['Localita']}<br>Tel: ${loc['Telefono']} E-mail: ${loc['E-mail']}<br><a href="${this.getUrlForLatLng(loc.lat, loc.lng)}"
           target="_blank" class="btn btn-primary mt-2">Apri la mappa</a><br><br>`
      this.infoWindow.open = true
    },
    shareButton() {
      if (navigator.share) {
        if (this.$gtag) {
          this.$gtag.event('share-button')
        }
        navigator.share({
          title: 'Promozione ' + this.shop,
          url: this.shopId ? 'https://bdso.it/?n=' + this.shopId : 'https://bdso.it'
        })
      }
    },
    copy() {
      this.$refs.clone.focus();
      document.execCommand('copy');
    },
  },
  components: {
    LogoSlider,
    ShareIcon,
    CookieLaw,
    Card,
  }
}
</script>

<style scoped>
.promo-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin-bottom: 60px;
}

@media screen and (min-width: 1000px) {
  .promo-cards {
    grid-gap: 100px 40px;
    padding: 20px 20px 40px;
  }
}

.promo-card {
  color: black;
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.10);
  position: relative;
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.promo-banner {
  border-radius: 0;
  box-shadow: none;
}

.promo-cards-container {
  border-radius: 20px 20px 0 0;
}

.details {
  padding: 20px;
}

.details > p {
  font-size: 18px;
  line-height: 30px;
}
</style>

<style>
.flip-card__top, .flip-card__bottom, .flip-card__back-bottom, .flip-card__back::before, .flip-card__back::after {
  width: 1.9em !important;
}

.text-primary {
  color: #348EDF !important;
}

.text-secondary {
  color: white !important;
}

.text-orange {
  color: #FF6B00 !important;
}

.text-blue {
  color: #004482 !important;
}

.title-font {
  font-family: 'Poppins', sans-serif;
  text-shadow: 0px 9px 9px rgba(51, 21, 0, 0.09), 0px 2px 5px rgba(51, 21, 0, 0.1), 0px 0px 0px rgba(51, 21, 0, 0.1);
  font-weight: bold;
}

.btn-primary {
  color: #FFF;
  background: linear-gradient(93.71deg, #C08B02 5.84%, #FF6B00 95.02%);
  box-shadow: 0px 43px 17px rgba(0, 0, 0, 0.01), 0px 24px 14px rgba(0, 0, 0, 0.05), 0px 11px 11px rgba(0, 0, 0, 0.09), 0px 3px 6px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  border: 3px solid #FDFDFD !important;
  font-weight: bold;
}

.btn-primary:hover {
  color: #fff;
  background-color: #CCC;
  border-color: #CCC;
}

.btn-secondary {
  color: #FFF;
  border: 0;
  background: #348EDF;
  box-shadow: 0px 43px 17px rgba(0, 0, 0, 0.01), 0px 24px 14px rgba(0, 0, 0, 0.05), 0px 11px 11px rgba(0, 0, 0, 0.09), 0px 3px 6px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  font-weight: bold;
}

.btn-secondary:hover {
  color: #348EDF;
  background-color: #FFF;
  border: 0;
}

.share-button {
  color: white;
  padding: 8px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.60);
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 5;
}

.share-button:hover {
  color: white;
}

.white-card {
  color: black;
  box-shadow: 0 10px 18px rgba(0, 0, 0, 0.05);
  background: #FFF;
  border-radius: 12px;
  padding: 15px 40px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.vci-container {
  border-radius: 20px 20px 0 0;
}

.vci-slider .line {
  background: rgb(96 223 230) !important;
}

.vci-slider .handle {
  border-color: rgb(96 223 230) !important;
  background: rgb(96 223 230) !important;
}

.highlight {
  background: url(/images/yellow-brushstroke.webp);
  background-repeat: no-repeat;
  background-size: 100% 95%;
  padding: 8px 0;

}

.card-image {
  width: 100%;
  border-radius: 0 0 20px 20px;
}

.rotated-box {
  transform: rotate(-3deg);
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  -webkit-transform-origin: bottom left;
  -ms-transform-origin: bottom left;
  transform-origin: bottom left;
  width: 120%;
  margin-left: -10%;
}
</style>
