<template>
  <div class="max-w-xl rounded-2xl shadow-lg p-3 bg-white">
    <slot></slot>

    <button @click="$emit('cta')"
            class="bg-orange-500 font-semibold text-white rounded-full tracking-widest py-3 px-5 uppercase shadow ">
      {{ btnText }}
    </button>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: ['btnText']
}
</script>

<style scoped>

</style>
